
.center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}

// 列表容器样式
@mixin setHeightAndWidth($w, $h) {
  width: $w;
  height: $h;
}
@mixin setFont($size, $lih, $col) {
  font-size: $size;
  font-family: Medium;
  line-height: $lih;
  color: $col;
}
.search {
  height: 86px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    img {
      @include setHeightAndWidth(8px, 10px);
      margin-right: 8px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
  }
  .search-box {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: Regular;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
}

.achievement-container-wrapper {
  // width: 100%;
  // height: 100%;
  width: 1260px;
  height: 456px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr; /// 二列二等分
  grid-gap: 0 0; // 行间距，列间距
  .achievement-container-left {
    border-right: 1px solid #f1f1f1;
    padding-left: 0px;
    &:hover {
      padding-left: 30px;
    }
  }
  .achievement-container-right {
    padding-left: 30px !important;
  }
  .achievement-container {
    // width: 408px;
    height: 156px;
    width: 630px;
    box-sizing: border-box;
    padding: 30px 30px 30px 0px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    // &:nth-child(odd) {
    //   border-right: 0px solid #f1f1f1;
    // }
    // &:nth-child(even) {
    //   border-left: 0px solid #f1f1f1;
    //   padding-left: 0px;
    //   &:hover {
    //     padding-left: 30px;
    //   }
    // }
    &:hover {
      box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
      z-index: 999;
    }
    .achidevments {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .top-achi {
        display: flex;
        flex: row;
        width: 100%;
        height: 100%;
        .img {
          width: 150px;
          height: 90px;
          border-radius: 6px;
          margin-right: 20px;
        }
        .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
        .word-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin-left: 20px;
          .word {
            font-size: 16px;
            font-family: Medium;
            font-weight: 500;
            color: #101010;
            line-height: 24px;
            white-space: nowrap;
          }
          .infoGroup {
            margin: 3px 0px 3px 0px;
            // background: red;
            display: flex;
            // padding: 20px;
            .itemLabel {
              width: 64px;
              white-space: nowrap;
              font-size: 12px;
              font-family: Regular;
              font-weight: 400;
              color: #101010;
              line-height: 18px;
              padding-left: 8px;
            }

            // .text {
            //   // width: 208px;
            //   // line-height: 20px;
            // }
          }
          .contact {
            // background: #2885ff;
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #8896a7;
            line-height: 18px;
          }

          .log-container {
            // margin-left: 20px;
            // border-radius: 8px;
            // padding: 7px 10px;
            // font-size: 14px;
            // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            // font-weight: 400;
            // color: #181515;
            // background: #3e9cff;
            // border-radius: 8px;
          }

          .profession {
            margin-top: 16px;
          }
        }
      }
      .inline {
        width: 100%;
        height: 1px;
        background: #eaeaea;
      }
      // .miniword-container {
      //   // align-self: flex-start;
      //   width: 332px;
      //   margin-top: 16px;
      //   margin-bottom: 16px;
      //   // background: red;
      //   display: flex;
      //   .mini-word {
      //     padding-left:20px ;
      //     // background: red;
      //     font-size: 12px;
      //     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //     font-weight: 400;
      //     color: #404040;
      //     line-height: 18px;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     display: -webkit-box;
      //     -webkit-line-clamp: 1;
      //     -webkit-box-orient: vertical;
      //   }
      // }
      .introduction {
        color: #8896a7;
        font-size: 12px;
        // left: 0;
        // top: 29px;
      }
      .miniword-container {
        // padding-top: 12px;
        width: 332px;
        // align-self: flex-start;
        align-items: center;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #404040;

        // margin-top: 16px;
        .mini-word {
          margin-top: -19px;
          margin-left: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          // -moz-binding:url('ellipsis.xml#ellipsis');
          // white-space:nowrap;
        }
        .no-word{
          margin-left: 20px;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #404040;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
